import React from "react";
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Header, HotTopics, Results, SearchInput } from "./components";
import { ContextList } from "./components/context-list";
import { DomainSwitch } from "./components/domain-switch";
import { PartnerList } from "./components/partners";
import { ResultsContainer } from "./containers";
import { ConfigContext, TDomain } from "./contexts";
import { PrivacyPolicy, TermsOfService, JoinPage } from "./components/pages";
import { ColorSchemeSwitcher } from "./components/color-scheme-switcher";

export type TColorScheme = "light" | "dark";
const isBrowserDefaultDark = () =>
  window.matchMedia("(prefers-color-scheme: dark)").matches;
const getDefaultColorScheme = (): TColorScheme => {
  const localStorageTheme = localStorage.getItem("colorScheme") as TColorScheme;
  const browserDefault = isBrowserDefaultDark() ? "dark" : "light";
  return localStorageTheme || browserDefault;
};
const body = document.getElementsByTagName("body")[0];

type TConfig = {
  domains: TDomain[];
};

function App() {
  const [colorScheme, setColorScheme] = React.useState<TColorScheme>(
    getDefaultColorScheme()
  );
  React.useEffect(() => {
    body.classList[colorScheme === "dark" ? "add" : "remove"]("dark");
    localStorage.setItem("colorScheme", colorScheme);
  }, [colorScheme]);

  const [userCount, setUserCount] = React.useState<number | null>(null);
  const [config, setConfig] = React.useState<TConfig | null>(null);
  React.useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/config/")
      .then((response) => response.json())
      .then((data) => setConfig(data as TConfig));
  }, []);
  return config ? (
    <ConfigContext.Provider
      value={{
        domains: config.domains,
        colorScheme,
        setColorScheme,
        userCount,
        setUserCount,
      }}
    >
      <BrowserRouter>
        <div className="app">
          <div className="navigation">
            <Link to="/" className="navigation__branding">
              <div className="navigation__branding__wrapper">
                <div className="navigation__branding__logo"></div>
                <div className="navigation__branding__text">
                  <div className="navigation__branding__name">GETCONTEXT</div>
                </div>
              </div>
            </Link>
            <div className="navigation__claim">AI Driven Crypto Aggregator</div>
            <ul className="navigation__main-navigation">
              <li className="navigation__main-navigation__item navigation__main-navigation__item--home">
                <Link to="/">Home</Link>
              </li>
              <li className="navigation__main-navigation__item navigation__main-navigation__item--about">
                <Link to="/about">About</Link>
              </li>
            </ul>
            <div className="navigation__footer">
              <ColorSchemeSwitcher />
              {userCount && (
                <div className="navigation__user-count">
                  {userCount} Users Online
                </div>
              )}
            </div>
          </div>
          <Routes>
            <Route path="about" element={<JoinPage />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route
              path="*"
              element={
                <ResultsContainer>
                  <div className="search-navigation">
                    <SearchInput />
                    <div className="search-navigation__buttons">
                      <DomainSwitch />
                    </div>
                  </div>
                  <Results />
                  <div className="sidebar">
                    <h2 className="sidebar__heading">Additional Info</h2>
                    <ContextList />
                  </div>
                </ResultsContainer>
              }
            />
          </Routes>
        </div>
      </BrowserRouter>
    </ConfigContext.Provider>
  ) : null;
}

export default App;
