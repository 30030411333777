import React from "react";
import { useContext } from "react";
import { TColorScheme } from "../app";

export type TDomain = {
  slug: string;
  name: string;
};

export type TConfigContext = {
  domains: TDomain[];
  colorScheme: TColorScheme;
  setColorScheme: (scheme: TColorScheme) => void;
  userCount: number | null;
  setUserCount: (count: number | null) => void;
};

export const ConfigContext = React.createContext<TConfigContext>({
  domains: [],
  colorScheme: "light",
  setColorScheme: () => void 0,
  userCount: null,
  setUserCount: () => void 0,
});

export const useConfig = () => useContext(ConfigContext);

export function getPageTitle(...parts: string[]) {
  return (parts || []).concat(["GETCONTEXT"]).join(" – ");
}
