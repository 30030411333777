import React, { useEffect } from "react";
import classNames from "classnames";
import { useSearch } from "../contexts";
import { Individual } from "./individual";
import { TagList, TTag } from "./tags";

const Legend: React.FC<{}> = () => (
  <ul className="legend">
    <li className="legend__item legend__item--founders">Founders</li>
    <li className="legend__item legend__item--advocates">Advocates</li>
    <li className="legend__item legend__item--blockchains">Blockchains</li>
    <li className="legend__item legend__item--source">Source</li>
    <li className="legend__item legend__item--time">Time</li>
    <li className="legend__item legend__item--tags">Tags</li>
  </ul>
);

export const ContextList: React.FC<{}> = () => {
  const {
    results: {
      individuals: _individuals,
      tags: _tags,
      sources,
      history,
      hot,
      ecosystem,
    },
    domain,
  } = useSearch();
  const tags = React.useMemo(
    () => _tags.filter((t) => t.type !== "Individual"),
    [_tags]
  );
  const individuals = React.useMemo(() => {
    const i = [..._individuals];
    _tags
      .filter((t) => t.type === "Individual")
      .forEach((t) => {
        if (
          i.findIndex(({ id }) => {
            return id === t.id;
          }) === -1
        )
          i.push(t);
      });
    return i;
  }, [_tags, _individuals]);
  const allTags = React.useMemo(
    () =>
      individuals
        .concat(tags)
        .concat(
          sources.map((s) => ({
            label: s.name,
            type: "source",
            class: "source",
          }))
        )
        .concat(
          history.map((h) => ({ type: "time", class: "time", label: h.date }))
        ),
    [tags, individuals, sources]
  );

  const eco = React.useMemo(() => {
    return {
      people: ecosystem
        .filter((e) => e.type === "individual")
        .sort((a, b) => b.rank - a.rank)
        .slice(0, 10),
      projects: ecosystem
        .filter((e) => e.type === "project")
        .sort((a, b) => b.rank - a.rank)
        .slice(0, 10),
      sources: ecosystem
        .filter((e) => !!e.site_name)
        .map((s) => ({
          ...s,
          class: "source",
          type: "source",
          label: s.site_name,
        }))
        .sort((a, b) => b.rank - a.rank)
        .slice(0, 10),
      orgs: ecosystem.filter((e) => ["company"].includes(e.type)).slice(0, 10),
    };
  }, [ecosystem]);
  const [selected, setSelected] = React.useState<
    null | "orgs" | "people" | "projects" | "sources"
  >(null);
  const select = React.useCallback(
    (v: keyof typeof eco) => {
      if (eco[v].length > 0) setSelected(v);
    },
    [setSelected, eco]
  );
  useEffect(() => select("people"), [select]);
  return (
    <div className="context-list">
      {ecosystem.length > 0 && (
        <>
          {domain?.name && (
            <div
              className={classNames(
                "ecosystem-navigation",
                `ecosystem-navigation--${selected}`
              )}
            >
              <div className="ecosystem-navigation__name">{domain?.name}</div>
              <a
                onClick={() => select("people")}
                className={classNames(
                  "ecosystem-navigation__item",
                  "ecosystem-navigation__item--people",
                  {
                    "ecosystem-navigation__item--disabled":
                      eco.people.length == 0,
                    "ecosystem-navigation__item--active": selected === "people",
                  }
                )}
              >
                People
              </a>
              <a
                onClick={() => select("projects")}
                className={classNames(
                  "ecosystem-navigation__item",
                  "ecosystem-navigation__item--projects",
                  {
                    "ecosystem-navigation__item--disabled":
                      eco.projects.length == 0,
                    "ecosystem-navigation__item--active":
                      selected === "projects",
                  }
                )}
              >
                Projects
              </a>
              <a
                onClick={() => select("orgs")}
                className={classNames(
                  "ecosystem-navigation__item",
                  "ecosystem-navigation__item--orgs",
                  {
                    "ecosystem-navigation__item--disabled":
                      eco.orgs.length == 0,
                    "ecosystem-navigation__item--active": selected === "orgs",
                  }
                )}
              >
                ORGs
              </a>
              <a
                onClick={() => select("sources")}
                className={classNames(
                  "ecosystem-navigation__item",
                  "ecosystem-navigation__item--sources",
                  {
                    "ecosystem-navigation__item--disabled":
                      eco.sources.length == 0,
                    "ecosystem-navigation__item--active":
                      selected === "sources",
                  }
                )}
              >
                Sources
              </a>
            </div>
          )}
          <div className="context-list__ecosystem">
            {eco.people.length > 0 && selected === "people" && (
              <>
                <h3>
                  {domain?.name ? (
                    <>
                      {domain.name}
                      <span>People</span>
                    </>
                  ) : (
                    "People"
                  )}
                </h3>
                <div className="trending-list">
                  <TagList tags={eco.people} />
                </div>
              </>
            )}
            {eco.projects.length > 0 && selected === "projects" && (
              <>
                <h3>
                  {domain?.name ? (
                    <>
                      {domain.name}
                      <span>Projects and Companies</span>
                    </>
                  ) : (
                    "Projects and Companies"
                  )}
                </h3>
                <div className="trending-list">
                  <TagList tags={eco.projects} />
                </div>
              </>
            )}
            {eco.orgs.length > 0 && selected === "orgs" && (
              <>
                <h3>
                  {domain?.name ? (
                    <>
                      {domain.name}
                      <span>Orgs</span>
                    </>
                  ) : (
                    "Orgs"
                  )}
                </h3>
                <div className="trending-list">
                  <TagList tags={eco.orgs} />
                </div>
              </>
            )}
            {eco.sources.length > 0 && selected === "sources" && (
              <>
                <h3>
                  {domain?.name ? (
                    <>
                      {domain.name}
                      <span>Sources</span>
                    </>
                  ) : (
                    "Sources"
                  )}
                </h3>
                <div className="trending-list">
                  <TagList tags={eco.sources} />
                </div>
              </>
            )}
          </div>
        </>
      )}
      {hot.length > 0 && (
        <div className="context-list__trending">
          <h3>
            {domain?.name ? (
              <>
                Trending<span>Currently trending in {domain.name}</span>
              </>
            ) : (
              "Trending"
            )}
          </h3>
          <div className="trending-list">
            <TagList tags={hot} />
          </div>
        </div>
      )}
      {allTags.length > 0 && (
        <>
          <h3>
            Result Structure<span>Items in the Current Result</span>
          </h3>
          <div>
            <TagList tags={allTags} />
          </div>
        </>
      )}
    </div>
  );
};
