import React from "react";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../contexts/config";
import Page from "./page";

export const PrivacyPolicy: React.FC<{}> = () => (
  <Page className="page--privacy">
    <Helmet>
      <title>{getPageTitle("Privacy Policy")}</title>
    </Helmet>
    <h1>GETCONTEXT PRIVACY POLICY</h1>
    <p>
      This Privacy Policy governs the manner in which GETCONTEXT LTD collects,
      uses, maintains and discloses information collected from users (each, a
      “User”) of the www.getcontext.org website (“Site”). This privacy policy
      applies to the Site and all products and services offered by GETCONTEXT
      LTD. Please also refer to the Terms of Service.
    </p>
    <h2>Personal identification information</h2>
    <p>
      We will collect personal identification information from Users only if
      they voluntarily submit such information to us. Users can always refuse to
      supply personally identification information, except that it may prevent
      them from engaging in certain Site related activities.
    </p>
    <h2>Non-personal identification information</h2>
    <p>
      We may collect non-personal identification information about Users
      whenever they interact with our Site. Non-personal identification
      information may include the browser name, the type of computer and
      technical information about Users means of connection to our Site, such as
      the operating system and the Internet service providers utilized and other
      similar information. We aim to conduct those measures through a third
      party (web traffic analytical tools) which has a privacy-friendly
      approach. We aim to share this information with the community.
    </p>
    <h2>Web browser cookies</h2>
    <p>
      Our Site does not use “cookies” to enhance User experience. User’s web
      browser places cookies on their hard drive for record-keeping purposes and
      sometimes to track information about them.{" "}
    </p>
    <h2>How we protect your information</h2>
    <p>
      We adopt appropriate data collection, storage and processing practices and
      security measures to protect against unauthorized access, alteration,
      disclosure or destruction of your personal information, username,
      password, transaction information and data stored on our site.
    </p>
    <p>
      You should be aware, however, that no method of transmission over the
      Internet or method of electronic storage is completely secure and we
      cannot guarantee the security of any information collected from or about
      you, or that no harmful code will enter our websites (for example viruses,
      bugs, trojan horses, spyware or adware). You also agree that we shall not
      be responsible for any loss or damage resulting from any unauthorized or
      unintended use, access, or disclosure of your information that is beyond
      our control.
    </p>
    <h2>Sharing your personal information</h2>
    <p>
      We do not sell, trade, or rent Users personal identification information
      to others. We may share generic aggregated demographic information not
      linked to any personal identification information regarding visitors and
      users with our business partners.
    </p>
    <h2>Changes to this privacy policy</h2>
    <p>
      GETCONTEXT LTD has the discretion to update this privacy policy at any
      time. When we do, we will revise the updated date at the bottom of this
      page. We encourage Users to frequently check this page for any changes to
      stay informed about how we are helping to protect the personal information
      we collect. You acknowledge and agree that it is your responsibility to
      review this privacy policy periodically and become aware of modifications
    </p>
    <h2>Your acceptance of these terms</h2>
    <p>
      By using this Site, you signify your acceptance of this policy and terms
      of service. If you do not agree to this policy, please do not use our
      Site. Your continued use of the Site following the posting of changes to
      this policy will be deemed your acceptance of those changes.{" "}
    </p>
    <h2>Governing Law</h2>
    <p>
      This Privacy Policy and your use of this website shall be governed in all
      respects by the laws of the UK and you agree to submit to the exclusive
      jurisdiction of the UK Courts in respect of any dispute relating to this
      Privacy Policy.
    </p>
    <h2>Disclaimer</h2>
    <p>
      To the maximum extent permitted by law, we shall not be liable in any
      event for any special, exemplary, punitive, indirect, incidental or
      consequential damages of any kind or for any loss of reputation or
      goodwill, whether based in contract, tort (including negligence), equity,
      strict liability, statute or otherwise, suffered as a result of
      unauthorized or unintended use, access or disclosure of any information
      provided by you to us.
    </p>
    <h2>Contacting us</h2>
    <p>
      If you have any questions about this Policy, the practices of this site,
      or your dealings with this site, please contact us at: join @ getcontext
      dot org
    </p>
  </Page>
);
