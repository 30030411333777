import React from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";

export type TTag = {
  label?: string;
  title?: string;
  class?: "time" | "person" | "source" | "category" | string;
  type?: string;
};

export const Tag: React.FC<{ tag: TTag }> = ({
  tag: { type, label, title },
}) => {
  const l = label ?? title ?? "";
  const slug = l.replaceAll(" ", "+");
  const [searchParams, _] = useSearchParams();
  const q = searchParams.get("q") ?? "";
  const prevFilter = searchParams.get("filter");
  switch (type) {
    case "time":
      const dt = l?.slice ? l.slice(0, 4) : l;
      return (
        <Link
          to={`?q=${(
            q.replace(/date:(\w+|"[\w\ ]+")/, "").trim() + ` date:${dt}`
          )
            .trim()
            .replaceAll(" ", "+")}`}
        >
          <time dateTime={l}>{dt}</time>
        </Link>
      );
    case "source":
      //@ts-ignore
      const source = l.includes(" ") ? `source:"${slug}"` : `source:${slug}`;
      const to = `?q=${(
        q.replace(/source:(\w+|"[-\w\ ]+")/, "").trim() +
        " " +
        source
      )
        .trim()
        .replaceAll(" ", "+")}&filter=latest`;
      return <Link to={to}>{l}</Link>;
    default:
      return <Link to={`?q=${slug}&filter=latest`}>{l}</Link>;
  }
};

const classMap = {
  individual: 3,
  source: 1,
  time: 2,
  company: 4,
  project: 5,
  tag: 6,
  unknown: 10,
  generic: 11,
};

export const TagList: React.FC<{
  tags: TTag[];
  children?: React.ReactNode;
}> = ({ tags, children }) => {
  const tagsSorted = React.useMemo(
    () =>
      tags.sort((a, b) => {
        const aVal =
          classMap[
            (a?.type?.toLowerCase() as keyof typeof classMap) ?? "unknown"
          ] || classMap["unknown"];
        const bVal =
          classMap[
            (b?.type?.toLowerCase() as keyof typeof classMap) ?? "unknown"
          ] ?? classMap["unknown"];
        if (aVal < bVal) {
          return -1;
        } else if (aVal === bVal) {
          return 0;
        } else {
          return 1;
        }
      }),
    [tags]
  );
  return (
    <ul className="tag-list">
      {children ? children : null}
      {tagsSorted.map(
        (t) =>
          t &&
          (t.label || t.title) && (
            <li className={`tag tag--${t.type?.toLowerCase() ?? "unknown"}`}>
              <Tag tag={t} />
            </li>
          )
      )}
    </ul>
  );
};
