import { useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSearch } from "../contexts/search";
import Item from "./item";
import { Partner } from "./partners";
import { TagList } from "./tags";

export const NoResults: React.FC<{}> = () => {
  return (
    <div>
      <h3>Nothing found</h3>
      <p>
        You may want to <Link to="/">clear your search and try again</Link>!
      </p>
    </div>
  );
};

export const HotTopics: React.FC<{}> = () => {
  const {
    results: { hot },
    params: { domain },
  } = useSearch();
  return hot.length > 0 ? (
    <div className="hot-topics">
      <h3 className="hot-topics__label">
        Currently Trending{domain ? ` in ${domain}` : ""}
      </h3>

      <TagList tags={hot.length > 6 ? hot.slice(0, 6) : hot}></TagList>
    </div>
  ) : null;
};

function slicePartners(
  partners: any[],
  position: number,
  count: number
): any[] {
  const result: typeof partners = [];
  const pos = position % partners.length;
  partners
    .slice(pos, Math.min(count + pos, partners.length))
    .forEach((x) => result.push(x));
  if (result.length < count)
    return result.concat(partners.slice(0, count - result.length));
  return result;
}

export const Results: React.FC<{}> = () => {
  const {
    results: { articles, partners },
    hasMore,
    loadMore,
    isLoading,
    q,
  } = useSearch();

  const [lastElement, setLastElement] = useState<any>(null);

  const observer = useMemo(
    () =>
      new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          loadMore();
        }
      }),
    [loadMore]
  );
  const observerRef = useRef(observer);
  useEffect(() => {
    const currentElement = lastElement;

    if (observerRef.current && observerRef.current !== observer) {
      currentElement && observerRef.current.unobserve(currentElement);
      observerRef.current = observer;
    }
    const currentObserver = observerRef.current;

    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [lastElement, observer]);

  return (
    <>
      <div className="results-list">
        {articles.length === 0 && !isLoading ? (
          <>
            <NoResults />
          </>
        ) : null}
        {articles &&
          articles.map((article, i) =>
            i === articles.length - 1 && !isLoading ? (
              <div ref={setLastElement} key={article.id}>
                <Item item={article} />
              </div>
            ) : (
              <>
                <Item item={article} key={article.id} />
              </>
            )
          )}
        {hasMore && <button onClick={loadMore}>Load More</button>}
      </div>
    </>
  );
};
