import React from "react";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../contexts/config";
import Page from "./page";

export const TermsOfService: React.FC<{}> = () => (
  <Page className="page--tos">
    <Helmet>
      <title>{getPageTitle("Terms of Service")}</title>
    </Helmet>
    <h1>GETCONTEXT TERMS OF SERVICE</h1>
    <p>
      PLEASE READ THESE TERMS OF USE (“TERMS”) CAREFULLY BEFORE USING THIS
      WEBSITE (“SITE”) OR THE SOFTWARE PRODUCTS DESCRIBED ON THIS WEBSITE
      (“PRODUCTS”).
    </p>
    <p>
      PLEASE REVIEW THE ARBITRATION PROVISION SET FORTH BELOW CAREFULLY, AS IT
      WILL REQUIRE ALL PERSONS TO RESOLVE DISPUTES ON AN INDIVIDUAL BASIS
      THROUGH FINAL AND BINDING ARBITRATION AND TO WAIVE ANY RIGHT TO PROCEED AS
      A REPRESENTATIVE OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
      PROCEEDING. BY ACCESSING OR USING THE SITE OR DOWNLOADING OR USING THE
      PRODUCTS,YOU EXPRESSLY ACKNOWLEDGE THAT YOU HAVE READ AND UNDERSTAND ALL
      OF THE TERMS OF THIS ARBITRATION PROVISION AND HAVE TAKEN TIME TO CONSIDER
      THE CONSEQUENCES OF THIS IMPORTANT DECISION.
    </p>
    <h2>What's in these terms?</h2>
    <p>
      These Terms tell you the rules for using our Site and Products. By
      accessing or using our Site or downloading or using our Products, you
      confirm that you accept these terms and agree to comply with them. If you
      do not agree to these terms, you must not use our Site or Products. We
      recommend printing a copy of these terms for future reference.
    </p>
    <p>These terms were last updated on Nov 1, 2022.</p>
    <h2>Who we are and how to contact us</h2>
    <p>
      The Site GETCONTEXT.ORG is operated by Getcontext Ltd. (“We”, “us” and
      “our”), a limited company registered in the UK under company number
      14068658, 205 CRESENT ROAD, BARNET EN4 8SB, LONDON / UK, CONTACT: join @
      getcontext dot org
    </p>
    <h2>System requirements</h2>
    <p>
      Use of our Site and our Products requires one or more compatible devices,
      internet access (fees may apply), and may require obtaining updates or
      upgrades from time to time. Because use of our Site or our Products
      involves hardware, software, and internet access, your ability to access
      and use the Site and the Products on it may be affected by the performance
      of these factors. High-speed internet access is recommended. You
      acknowledge and agree that such system requirements, which may change from
      time to time, are your responsibility.
    </p>
    <h2>Prohibited Use</h2>
    <p>
      You agree that you will not use the Site or Products to: (i) modify,
      disassemble, decompile, prepare derivative works of, reverse engineer or
      otherwise attempt to gain access to the source code of the Site; (ii)
      knowingly or negligently use the Site in a way that abuses, interferes
      with, or disrupts our networks, or the Site; (iii) engage in activity that
      is illegal, fraudulent, false, or misleading, (iv) transmit through our
      Site or Products any material that may infringe the intellectual property
      or other rights of third parties; (v) copy any features, functions or
      graphics of the Site; or (vi) use our Site or our Products to communicate
      any message or material that is harassing, libelous, threatening, obscene,
      indecent, would violate the intellectual property rights of any party or
      is otherwise unlawful, that would give rise to civil liability, or that
      constitutes or encourages conduct that could constitute a criminal
      offense, under any applicable law or regulation; (vii) upload or transmit
      any software, content or code that does or is intended to harm, disable,
      destroy or adversely affect performance of the Site in any way or which
      does or is intended to harm or extract information or data from other
      hardware, software or our Products or networks or other users of the Site;
      (viii) engage in any activity or use the Site in any manner that could
      damage, disable, overburden, impair or otherwise interfere with or disrupt
      the Site, or any servers or networks connected to the Site or our security
      systems. (ix) use the Site or Products in violation of any of our policies
      or in a manner that violates applicable law, including but not limited to
      anti-spam, export control, privacy, and anti-terrorism laws and
      regulations and laws requiring the consent of subjects of audio and video
      recordings, and you agree that you are solely responsible for compliance
      with all such laws and regulations.
    </p>
    <h2>No Representations and Warranties</h2>
    <p>
      We make no representations, warranties, covenants or guarantees to you of
      any kind and, to the extent permitted by applicable laws, we expressly
      disclaim all representations, warranties, covenants or guarantees,
      express, implied or statutory, with respect to our Products and Site. Our
      Products and Site are offered strictly on an as-is, where-is basis and,
      without limiting the generality of the foregoing, are provided without any
      representation as to merchantability or fitness for any particular purpose
      or any representation or warranty that our Products and Site will be
      operational, errorfree, secure, confidential, reliable, or safe, or that
      our Products and Site will function without disruptions, delays or
      imperfections.
    </p>
    <h2>We may make changes to these Terms</h2>
    <p>
      We amend these Terms from time to time. Every time you wish to use our
      Site or Products, please check these Terms to ensure you understand the
      terms that apply at that time. When we do this, we will post the revised
      Terms on this page and indicate the date of such amendments.
    </p>
    <h2>
      We may make changes to our Site or Products, including based on your
      feedback
    </h2>
    <p>
      We may update and change our Site or Products from time to time and
      without notice to you. If you provide feedback or suggestions about our
      Products, Site, or any content available on our Site, then we may act on
      that feedback or those suggestions on an unrestricted basis and without
      any obligations to you (including any obligation to compensate you or to
      keep that feedback confidential).
    </p>
    <h2>We may suspend or withdraw our Site or Products</h2>
    <p>
      We do not guarantee that our Products, Site, or any content on it will
      always be available or uninterrupted. We may suspend, withdraw, or
      restrict the availability of all or any part of our Site and our Products
      for business and operational reasons. We will try to give reasonable
      notice of any suspension or withdrawal by notice on the Site.
    </p>
    <h2>Do not rely on information on this Site</h2>
    <p>
      The content on our Site is provided for general information only. It is
      not intended to amount to a recommendation or advice on which you should
      rely. You must obtain professional or specialist advice before taking, or
      refraining from, any action based on the content on our Site. Although we
      make reasonable efforts to update the information on our Site, we make no
      representations, warranties or guarantees, whether express or implied,
      that the content on our Site is accurate, complete or up to date
    </p>
    <h2>We are not responsible for websites we link to</h2>
    <p>
      Where our Site contains links to other sites and resources provided by
      third parties or affiliates, these links are only provided for your
      information. Such links should not be interpreted as approval by us of
      those linked websites or information you may obtain from them. We have no
      control over the contents of those sites or resources, or their privacy
      settings, policies and/or procedures. You are solely responsible for
      reviewing the information provided by those sites or resources.
    </p>
    <h2>Rules about linking to our Site</h2>
    <p>
      You may link to our home page, provided you do so in a way that is fair
      and legal and does not damage our reputation or take advantage of it. You
      must not establish a link in such a way as to suggest any form of
      association, approval or endorsement on our part where none exists. You
      must not establish a link to our Site on any website you do not own. Our
      Site must not be framed on any other site, nor may you create a link to
      any part of our Site other than the home page. We reserve the right to
      withdraw linking permission without notice. If you wish to link to or use
      the content on our Site other than that set out above, please contact us
      through the email address provided above.
    </p>
    <h2>Our responsibility for loss or damage suffered by you</h2>
    <p>
      We will not be liable for: loss of profits, sales, business, or revenue;
      business interruption; loss of anticipated savings; loss of business
      opportunity, goodwill or reputation; or any indirect or consequential loss
      or damage. We are not responsible for viruses and you must not introduce
      them. We do not guarantee that our Products, Site or any content on it
      will be secure or free from bugs or viruses. You are responsible for
      configuring your information technology, computer programmes and platform
      to access our Site and our Products. You should use your own virus
      protection software.
    </p>
    <p>
      You must not misuse our Site or our Products by knowingly introducing
      viruses, trojans, worms, logic bombs or other malicious or technologically
      harmful material. You must not attempt to gain unauthorised access to our
      Site, the server on which our Site is stored or any server, computer or
      database connected to our Site. You must not attack our Site via a
      denial-of-service attack or a distributed denial-of-service attack. You
      may commit a criminal offence under the BVI Computer Misuse and Cybercrime
      Act 1990 or other applicable legislation by breaching this provision. We
      will report any such breach to the relevant law enforcement authorities
      and co-operate with those authorities by disclosing your identity to them.
      In the event of such a breach, your right to use our Site will cease
      immediately.
    </p>
    <h2>Indemnity</h2>
    <p>
      You agree only to use the Site and our Products in accordance with these
      terms. You agree that you will compensate Getcontext Ltd. (and our
      employees, contractors, directors, officers, agents, affiliates and
      suppliers) in full for any damages, losses, costs and expenses, including
      reasonable legal fees, we incur as a result of any breach by you of these
      terms or any liability we incur because of the use of our Site or our
      Products by you
    </p>
    <h2>General (including mandatory arbitration)</h2>
    <p>
      Entire agreement: These terms of use constitute the entire agreement
      between you and Getcontext Ltd. and supersede any prior agreements between
      you and Getcontext Ltd. You may also be subject to additional terms of
      service that may apply when you use affiliate or third-party services,
      third-party content or third-party software.
    </p>
    <p>
      Governing Law: These Terms shall be governed by and construed and enforced
      in accordance with the laws of the UK and shall be interpreted in all
      respects as a BVI contract. Any transaction, dispute, controversy, claim
      or action arising from or related to your access or use of our Site or our
      Products or these Terms shall be governed by the laws of the UK, exclusive
      of choice-of-law principles.
    </p>
  </Page>
);
