import React from "react";
import Page from "./page";
import BCorp from "./b-corp.png";
import { Helmet } from "react-helmet";
import { getPageTitle } from "../../contexts/config";
import Banner from "../../assets/images/btn-banner.jpg";
import Trees from "../../assets/images/trees2.png";
import ComputationalKnowledge from "../../assets/images/computational-knowledge.png";

const Aside: React.FC<{}> = () => (
  <div className="page--join__aside">
    <div className="page--join__branding">
      <div className="logo-wrapper">
        <div className="getcontext-logo"></div>
      </div>

      <div>
        <h3>
          GET<span>CONTEXT</span>
        </h3>
        On What’s Coming
      </div>
    </div>
    <ul>
      <li>Web3 Enabled</li> <li>Information Bubble Free</li>
      <li>Absolute Privacy</li>
      <li>Social Economy Driven</li>
    </ul>
    <img className="page--join__aside__banner" src={Banner} alt="" />
  </div>
);

export const JoinPage: React.FC<{}> = () => (
  <Page className="page page--join" sidebar={<Aside />}>
    <Helmet>
      <title>{getPageTitle("Join")}</title>
    </Helmet>
    <div>
      <h1>
        Getcontext <span>Beta</span>
      </h1>
      <p>
        GETCONTEXT is the worlds first contextual news and knowledge aggregator.
        Join us with a "social economy" like approach (e.g. see Ecosia).
      </p>
      <p>join @ getcontext dot org</p>
      <div className="page--join__approach">
        <h2>Our approach and philosophy:</h2>

        <ul>
          <li>Community &amp; Social Economy Driven</li>
          <li>Augmented Through Machine Learning &amp; Knowledge Graphs</li>
          <li>Information Bubble Free</li>
          <li>
            Privacy Aware{" "}
            <span>(No Tracking, No Cookies, No Personal Data Collecting)</span>
          </li>
          <li>
            Web3 Enabled{" "}
            <span>(Tokenomics, Community-Payment-Models, etc)</span>
          </li>
          <li>Goals: B-Corp, Computational Knowledge &amp; Planting Trees</li>
        </ul>
        <div className="page--join__approach__logos">
          <img src={BCorp} alt="" />
          <img src={ComputationalKnowledge} alt="" />
          <img src={Trees} alt="" />
        </div>
      </div>
      <p>
        GETCONTEXT LTD
        <br />
        COMPANY NO. 14068658
        <br />
        205 CRESENT ROAD
        <br />
        BARNET EN4 8SB
        <br />
        LONDON / UK
        <br />
        CONTACT: join @ getcontext dot org
        <br />
      </p>
    </div>
  </Page>
);
