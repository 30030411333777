import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import Banner from "../../assets/images/btn-banner.jpg";

export const Sidebar: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => <div className="sidebar">{children}</div>;

export const Page: React.FC<{
  children: React.ReactNode;
  sidebar?: React.ReactNode;
  className?: string;
}> = ({ children, className, sidebar }) => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  return (
    <>
      <main className={className ? `page ${className}` : "page"}>
        <div className="page__content">{children}</div>

        <footer>
          <div className="legal-text">
            GETCONTEXT LTD does not assume responsibility for the content of
            sites to which it links and the way in which search results are
            displayed. To learn more please read our privacy policy and our
            terms of service.
          </div>

          <div className="footer__links">
            <span>2022 GETCONTEXT LTD</span>
            <Link to="/privacy-policy">Privacy Notice</Link>
            <Link to="/terms-of-service">Terms of Service</Link>
          </div>
          <img className="footer__banner" src={Banner} alt="" />
        </footer>
      </main>
      {sidebar ? <Sidebar>{sidebar}</Sidebar> : null}
    </>
  );
};

export default Page;
