import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSearch } from "../contexts";
import { LoadingSpinner } from "./loading-spinner";

type ArgumentTypes<F extends Function> = F extends (...args: infer A) => any
  ? A
  : never;

function debounce<T extends Function>(fn: T, delay: number) {
  let timer: ReturnType<typeof setTimeout> | null = null;
  return function (...a: ArgumentTypes<T>) {
    //@ts-ignore
    const context = this;
    const args = arguments;
    timer && clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
}

export const SearchInput: React.FC<{}> = () => {
  const { q, setSearch, isLoading } = useSearch();
  const ref = useRef<HTMLInputElement>(null);
  const setSearchDebounced = useMemo(
    () => debounce(setSearch, 200),
    [setSearch]
  );
  const [value, setValue] = useState(q);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      setSearchDebounced(e.target.value);
    },
    [setSearchDebounced]
  );
  useEffect(() => void (value !== q && setValue(q)), [q]);

  const clear = useCallback(() => {
    setSearch("");
    ref.current && ref.current.focus();
  }, [setSearch]);
  return (
    <div className="search-input">
      <input
        spellCheck={false}
        ref={ref}
        className="search-input__input"
        value={value}
        onChange={handleChange}
        autoFocus
        //placeholder="Search..."
      />
      <i className="search-input__input__icon"></i>
      {!!q && !isLoading && (
        <button className="search-input__clear" onClick={clear}>
          ⨯
        </button>
      )}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default SearchInput;
