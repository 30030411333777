import React from "react";
import { useConfig } from "../contexts";

export const ColorSchemeSwitcher = () => {
  const { colorScheme, setColorScheme } = useConfig();
  const nextColorScheme = colorScheme === "light" ? "dark" : "light";
  const handleColorSchemeClick = React.useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      setColorScheme(nextColorScheme);
    },
    [nextColorScheme, setColorScheme]
  );
  return (
    <button
      className={`color-scheme-button color-scheme-button--${nextColorScheme}`}
      onClick={handleColorSchemeClick}
      title="Change Color Scheme"
    ></button>
  );
};
