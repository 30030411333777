import { TResultArticle } from "../containers";
import { TagList, TTag } from "./tags";
import ReactTimeAgo from "react-time-ago";
import React from "react";
import classNames from "classnames";
import useIntersectionObserver from "@react-hook/intersection-observer";

export const Item: React.FC<{ item: TResultArticle }> = ({ item }) => {
  const tags = React.useMemo(
    () =>
      [
        ...(item?.date
          ? [{ type: "time", label: item.date, class: "time" }]
          : []),
        ...(item?.source?.id
          ? [
              {
                type: "source",
                label: item.source.site_name,
                class: "source",
                slug: item.source.site_slug,
              },
            ]
          : []),
      ].concat(item.related),
    [item?.related, item?.source?.id, item?.date]
  );
  const date = React.useMemo(() => {
    const now = Date.now();
    const itemDate = Date.parse(item.date);
    return itemDate > now ? new Date() : itemDate;
  }, [item.date]);
  const youtubeId = item.url.match(/www.youtube.com\/watch\?v\=([\-\w]+)/)?.[1];
  const containerRef = React.useRef<HTMLDivElement>(null);
  const lockRef = React.useRef(false);
  const { isIntersecting } = useIntersectionObserver(containerRef);
  if (isIntersecting && !lockRef.current) {
    lockRef.current = true;
  }
  return (
    <div className="item" ref={containerRef}>
      <a href={item.url} target="_blank" rel="noreferrer">
        {item.label}
      </a>
      <div
        className={classNames("item__content", {
          "item__content--youtube": youtubeId,
        })}
      >
        <div>
          <ul className="item__info">
            <li className="item__info__item">Article</li>
            <li className="item__info__item">
              <ReactTimeAgo date={date} />
            </li>
          </ul>
          <h2 className="item__title">{item.label}</h2>
          {youtubeId && lockRef.current ? (
            <div className="item__video">
              <iframe
                title={item.label}
                src={`https://www.youtube.com/embed/${youtubeId}`}
                width="100%"
                height="100%"
                frameBorder="0"
              ></iframe>
            </div>
          ) : null}
          <p className="item__description">
            {item.description.length > 360
              ? item.description.slice(0, 360) + "…"
              : item.description}
          </p>
          <TagList tags={tags}></TagList>
        </div>
        {item.image && (
          <img className="item__image" src={item.image[0].url} alt="" />
        )}
      </div>
    </div>
  );
};

export default Item;
