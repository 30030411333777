import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Helmet } from "react-helmet";
import {
  useSearchParams,
  useMatch,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { getPageTitle, useConfig } from "../contexts/config";
import { SearchContext } from "../contexts/search";

//const API_URL = "https://www.getcontext.org/api";
const API_URL = process.env.REACT_APP_API_URL;

export type TSearchParams = {
  domain: string | null;
  page: number;
  page_size: number;
  q: string;
  filter: "trending" | "latest";
  type: "media" | "social" | "";
};

export const defaultParams: TSearchParams = {
  domain: null,
  page: 1,
  page_size: 15,
  q: "",
  filter: "trending",
  type: "",
};

async function getResults(params: TSearchParams) {
  const p = Object.assign({}, defaultParams, params);
  const response = await fetch(
    `${API_URL}/items/?q=${p.q}&page_size=${p.page_size}${
      p.page ? `&page=${p.page}` : ""
    }${p.type ? `&type=${p.type}` : ""}${
      p.domain ? `&domain=${p.domain}` : ""
    }${p.filter ? `&filter=${p.filter}` : ""}`
  );
  if (response.status === 200) return response.json();
  return null;
}

export type TResultIndividual = any;
export type TResultArticle = any;
export type TResultTag = any;
export type TResultDomain = { key: string };
export type TResultSource = { name: string };
export type TResultHistory = { date: string };

export type TResultItems = {
  articles: TResultArticle[];
  individuals: TResultIndividual[];
  tags: TResultTag[];
  hot: TResultTag[];
  ecosystem: TResultTag[];
  domains: TResultDomain[];
  total: number;
  source_count: number;
  sources: TResultSource[];
  history: TResultHistory[];
  partners: any;
};

export const ResultsContainer: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [results, setResults] = useState<TResultItems>({
    articles: [],
    individuals: [],
    tags: [],
    hot: [],
    partners: [],
    domains: [],
    sources: [],
    history: [],
    ecosystem: [],
    total: 0,
    source_count: 0,
  });
  const navigate = useNavigate();
  const config = useConfig();
  const [params, setParams] = useState(defaultParams);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const isLoadingRef = useRef<boolean>();
  const prevParamsRef = useRef<TSearchParams>();

  const q = searchParams.get("q")?.replace("+", " ") ?? "";
  const match = useMatch("/:domain");
  const domain = match?.params.domain ?? defaultParams.domain;
  const filter = searchParams.get("filter") ?? defaultParams.filter;
  const type = searchParams.get("type") ?? defaultParams.type;

  const nextParams: TSearchParams = useMemo(() => {
    const d = domain ?? defaultParams.domain;
    const prev = prevParamsRef?.current;
    const n = Object.assign({}, defaultParams, params, {
      q: q ?? defaultParams.q,
      filter,
      type,
      domain: d,
      page:
        prev?.q !== q ||
        prev?.domain !== d ||
        prev?.filter !== filter ||
        prev?.type !== type
          ? 1
          : params.page,
    });
    return n;
  }, [q, domain, params, filter, type]);

  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);

  useEffect(() => {
    if (isLoadingRef.current) return;
    setIsLoading(true);
    isLoadingRef.current = true;

    getResults(nextParams).then((data) => {
      if (data) {
        config.setUserCount(data.user_count);
        if (nextParams.page === 1) {
          setResults(Object.assign({}, data));
          prevParamsRef.current = nextParams;
        } else {
          setResults((r) =>
            prevParamsRef.current &&
            nextParams.page === prevParamsRef.current.page + 1
              ? Object.assign({}, r, {
                  articles: r.articles.concat(data.articles),
                })
              : Object.assign({}, r)
          );
          prevParamsRef.current = nextParams;
        }
      }
      isLoadingRef.current = false;
      setIsLoading(false);
    });
  }, [nextParams, isLoadingRef]);

  const setSearch = useCallback(
    (q: string) => {
      setParams(defaultParams);
      const prevFilter = searchParams.get("filter");
      const prevQ = searchParams.get("q");
      const search = q.replaceAll(" ", "+") || "";
      const filter = !prevQ ? "latest" : prevFilter;
      navigate(
        `/${
          q
            ? `?q=${search}${
                filter && filter !== defaultParams.filter
                  ? `&filter=${filter}`
                  : ""
              }`
            : ""
        }`
      );
    },
    [searchParams, navigate]
  );
  const hasMore =
    (prevParamsRef.current?.page ?? defaultParams.page) *
      (prevParamsRef.current?.page_size ?? defaultParams.page_size) <
    results.total;

  const loadMore = useCallback(() => {
    !isLoadingRef.current &&
      hasMore &&
      setParams((p) =>
        Object.assign({}, p, { page: (prevParamsRef.current?.page ?? 0) + 1 })
      );
  }, [isLoadingRef, hasMore]);

  const title: string[] = useMemo(
    () => [...(q ? [q] : []), ...(domain ? [domain] : [])],
    [q, domain]
  );

  const ctxValue = React.useMemo(
    () => ({
      results,
      isLoading,
      setSearch,
      q,
      params: prevParamsRef.current || defaultParams,
      setParams,
      loadMore,
      hasMore,
      domain: {
        name: config.domains.find((d) => d.slug == domain)?.name ?? domain,
      },
    }),
    [
      domain,
      results,
      isLoading,
      setSearch,
      q,
      prevParamsRef.current,
      setParams,
      loadMore,
      hasMore,
    ]
  );

  return (
    <SearchContext.Provider value={ctxValue}>
      <Helmet>
        <title>{getPageTitle(...title)}</title>
      </Helmet>
      {children}
    </SearchContext.Provider>
  );
};

export default ResultsContainer;
