import { useMemo } from "react";
import { Link, useLocation, useMatch, useSearchParams } from "react-router-dom";
import { useConfig, useSearch } from "../contexts";
import React from "react";

function sanitizeFilterUrl(url: string) {
  return url.replaceAll("%3A", ":").trim();
}

export const DomainSwitch: React.FC = () => {
  const config = useConfig();
  const labelMap = useMemo(
    () =>
      config.domains.reduce((acc, cur) => {
        acc[cur.slug] = cur.name;
        return acc;
      }, {} as Record<string, string>),
    [config.domains]
  );
  const {
    results: { domains },
  } = useSearch();
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const match = useMatch("/:domain");
  const filterUrls = React.useMemo(() => {
    let params = new URLSearchParams(search);

    params.set("filter", "trending");
    const trending = sanitizeFilterUrl(params.toString());
    params.set("filter", "latest");
    const latest = sanitizeFilterUrl(params.toString());

    params = new URLSearchParams(search);
    params.set("type", searchParams.get("type") === "media" ? "" : "media");
    const media = sanitizeFilterUrl(params.toString());
    params.set("type", searchParams.get("type") === "social" ? "" : "social");
    const social = sanitizeFilterUrl(params.toString());

    return { trending, latest, media, social };
  }, [search, searchParams]);
  return (
    <div>
      {domains && (
        <nav className="domain-switch">
          <ul>
            <li className="domain-switch__item" key={`domain-switch-all`}>
              <Link
                className={!match?.params.domain ? "active" : undefined}
                to={`/${search ? `${search}` : ""}`}
              >
                All
              </Link>
            </li>
            {domains.map(
              ({ key }) =>
                labelMap[key] && (
                  <li
                    className="domain-switch__item"
                    key={`domain-switch-${key}`}
                  >
                    <Link
                      className={
                        match?.params.domain === key.toLowerCase()
                          ? "active"
                          : undefined
                      }
                      to={`/${key.toLowerCase()}${search ? `${search}` : ""}`}
                    >
                      {labelMap[key as string] ?? key}
                    </Link>
                  </li>
                )
            )}
          </ul>
        </nav>
      )}
      <div className="search-filters">
        <ul className="search-filters__trending">
          <li>
            <Link
              className={
                searchParams.get("filter") === "trending" ||
                !searchParams.get("filter")
                  ? "active"
                  : undefined
              }
              to={`?${filterUrls.trending}`}
            >
              Trending
            </Link>
          </li>
          <li>
            <Link
              className={
                searchParams.get("filter") === "latest" ? "active" : undefined
              }
              to={`?${filterUrls.latest}`}
            >
              Latest
            </Link>
          </li>
        </ul>
        <ul className="search-filters__type">
          <li>
            <Link
              className={
                searchParams.get("type") === "media" ? "active" : undefined
              }
              to={`?${filterUrls.media}`}
            >
              Media
            </Link>
          </li>
          <li>
            <Link
              className={
                searchParams.get("type") === "social" ? "active" : undefined
              }
              to={`?${filterUrls.social}`}
            >
              Social
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
