import React, { useContext } from "react";
import { defaultParams, TResultItems, TSearchParams } from "../containers";

export type TSearchContext = {
  results: TResultItems;
  isLoading: boolean;
  setSearch: (q: string) => void;
  q: TSearchParams["q"];
  params: TSearchParams;
  setParams: (params: any) => void;
  loadMore: () => void;
  hasMore: boolean;
  domain: any;
};

export const SearchContext = React.createContext<TSearchContext>({
  isLoading: false,
  results: {
    articles: [],
    individuals: [],
    tags: [],
    hot: [],
    partners: [],
    domains: [],
    total: 0,
    source_count: 0,
    sources: [],
    history: [],
    ecosystem: [],
  },
  q: "",
  params: defaultParams,
  setSearch: () => void 0,
  setParams: () => void 0,
  loadMore: () => void 0,
  hasMore: false,
  domain: null,
});

export const useSearch = () => useContext(SearchContext);
